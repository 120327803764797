import request from '@/utils/request'

//抖音门店分页列表
export function douYinShopList(params) {
  return request({
    url: '/api/system/o2othird/douyin/shop/list',
    method: 'get',
    params
  })
}

//新增抖音门店
export function douYinShopSave(data) {
  return request({
    url: '/api/system/o2othird/douyin/shop/saveShop',
    method: 'post',
    data
  })
}

//匹配抖音门店查询
export function matchTaskQuery(shopId) {
  return request({
    url: '/api/system/o2othird/douyin/shop/matchTaskQuery?shopId=' + shopId,
    method: 'get'
  })
}

//匹配抖音门店
export function matchTaskSubmit(data) {
  return request({
    url: '/api/system/o2othird/douyin/shop/matchTaskSubmit',
    method: 'put',
    data
  })
}

//同步门店账户
export function shopPoiSync(shopId) {
  return request({
    url: '/api/system/o2othird/douyin/shop/shopPoiSync',
    method: 'post',
    data: {shopId }
  })
}

//修改抖音门店
export function updateShop(data) {
  return request({
    url: '/api/system/o2othird/douyin/shop/updateShop',
    method: 'put',
    data
  })
}

//抖音门店列表(下拉选项)
export function listOptionShop() {
  return request({
    url: '/api/system/o2othird/douyin/shop/listOptionShop',
    method: 'get'
  })
}

//获取抖音门店详情
export function getShopDetail(shopId) {
  return request({
    url: '/api/system/o2othird/douyin/shop/getShopDetail?shopId=' + shopId,
    method: 'get'
  })
}

//删除抖音门店
export function getShopDelete(data) {
  return request({
    url: '/api/system/o2othird/douyin/shop/delete',
    method: 'delete',
    data
  })
}

//门店同步查询
export function shopPoiSyncQuery(data) {
  return request({
    url: '/api/system/o2othird/douyin/shop/shopPoiSyncQuery',
    method: 'POST',
    data
  })
}

//获取线上抖音门店分页列表(用于匹配抖音门店)
export function shopPoiQueryt(params) {
  return request({
    url: '/api/system/o2othird/douyin/shop/shopPoiQuery',
    method: 'get',
    params
  })
}

//还原
export function douYinShopRestore(data) {
  return request({
    url: '/api/system/o2othird/douyin/shop/revert',
    method: 'post',
    data
  })
}